.b-icones{
    .b-icones_component{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

    @media (max-width: $from-small){
        .b-icones_component{
            margin-bottom: 3rem;
        }
    }
}
