.c-heading {
    line-height: $line-height-h;
    font-family: $font-myriad-pro-semibold-condensed;

    &.-h1 {
        font-size: rem($font-size-h1);
        font-weight: $bold;
        line-height: $line-height-default;
        // @media (max-width: $to-small) {
        //   font-size: calc(#{rem($font-size-h1)} - 10px);
        // }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        // @media (max-width: $to-small) {
        //   font-size: calc(#{rem($font-size-h2)} - 5px);
        // }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        // @media (max-width: $to-small) {
        //   font-size: calc(#{rem($font-size-h3)} - 5px);
        // }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        font-weight: $bold;
        // @media (max-width: $to-medium) {
        //   font-size: calc(#{rem($font-size-h4)} - 4px);
        // }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
    }

    &.-h6 {
      font-family: $font-myriad-pro-semibold;
        font-size: rem($font-size-h6);
    }

    &.-big {
      font-size: rem($font-size-big);
    }

    &.-big2 {
        font-size: rem($font-size-big2);
    }

    &.-upper{
        text-transform: uppercase;
    }

    &.-small{
        font-size: rem(14px);
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

