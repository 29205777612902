.b-transition{
    .b-transition_content{
        transform: translateY(rem(-85px));

        .b-transition_content_text{
            display: flex;
            margin: auto;
        }
    }

    .b-transition_img{
        height: rem(150px);

        img{
            object-fit: contain;
        }
    }
}
