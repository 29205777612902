.c-text{
    
    blockquote {
        border-radius: rem(8px);
        background-color: $grey-light;
        padding: rem($unit-small);
        margin: 0;
        color: $black;
        font-size: rem($font-size-h4);
        font-weight: 500;
    }

    a:not(.c-button) {
        color: $primary-light;
        text-decoration: underline;
        .c-button_icon {
            display: none;
        }
    }

    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding: rem($unit-thin) 0;
            padding-left: calc(#{rem(8px)} + #{rem($unit-tiny)});
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(8px);
                height: rem(8px);
                // background-color: currentColor;
                background-color: $primary;
                border-radius: 50%;
                top: calc(#{$font-size} - #{rem(8px)} + #{rem($unit-thin)});
            }
        }
    }
    ol {
        counter-reset: ol_counter; /* on initialise et nomme un compteur */
        padding: 0;
        li {
            padding: 0;
            list-style-type: none;
            counter-increment: ol_counter; /* on incrémente le compteur à chaque nouveau li */
            position: relative;
            padding-left: calc(#{rem(12px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-thin);
            &::before {
                content: counter(ol_counter)"."; /* on affiche le compteur */
                width: rem(12px);
                height: rem(12px);
                position: absolute;
                font-weight: $bold;
                left: 0;
                color: $primary;
            }
        }
    }

    img {
        border-radius: rem(8px);
        overflow: hidden;
        max-width: 100%;
    }

    .alignleft,
    img.alignleft {
        display: inline;
        float: left;
        margin-right: 1.5em;
    }
    .alignright,
    img.alignright {
        display: inline;
        float: right;
        margin-left: 1.5em;
    }
    .aligncenter,
    img.aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}
