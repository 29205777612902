.b-slider {
    .o-ratio_content_bg {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background: transparent linear-gradient(180deg, #009CB5B4 0%, #009CB500 100%) 0% 0% no-repeat padding-box;
            mix-blend-mode: multiply;
        }
    }
    .c-slider_slide {
        height: auto!important;
    }
    .c-slider_content {
        position: relative;
        z-index: 2;
    }

    .b-slider_img{
        // max-width: rem(842px);
        height: rem(438px);
        overflow: hidden;
        border-radius: 1rem;
    }

    .c-slider {
        position: relative;
    }
    .c-slider_nav{
        position: absolute;
        top: 50%;
        bottom: 50%;
        z-index: 2;
        cursor: pointer;
        &.-left {
            left: rem($unit-small);
        }
        &.-right {
            right: rem($unit-small);
        }
        // .b-cards_slider_left{
        //     position: absolute;
        //     top: 50%;
        //     bottom: 50%;
        //     left: 4rem;
        // }

        // .b-cards_slider_right{
        //     position: absolute;
        //     top: 50%;
        //     bottom: 50%;
        //     right: 4rem;
        // }

        // @media (max-width: $from-tiny) {
        //     .b-cards_slider_left{
        //         left: 1rem;
        //     }

        //     .b-cards_slider_right{
        //         right: 0;
        //     }
        // }
    }
}

// .b-slider_left, .b-slider_right{
//     position: absolute;
//     top: 50%;
//     bottom: 50%;
//     z-index: 1;
//     cursor: pointer;
// }

// .b-slider_left{
//     left: 0;
// }

// .b-slider_right{
//     right: 2rem;
// }
