.b-cta{
    .b-cta_wrap {
        position: relative;
        min-height: rem(450px);
        display: flex;
        align-items: flex-end;
        padding: rem($unit);
        background: linear-gradient(transparent, $primary-dark calc(100% - #{rem($unit)}));
        border-radius: rem(8px);
        overflow: hidden;
    }
    .c-cta_content {
        width: 100%;
        display: flex;
        align-items: flex-end;
        grid-gap: rem($unit);
    }

    @media(max-width: $from-medium){
        .c-cta_content{
            flex-direction: column;
        }
    }

    @media(max-width: $from-tiny){
        .b-cta_wrap{
            padding: 2rem;
        }
    }
}
