.b-accueil-intro {
    // height: calc(100vh - #{rem($header_height)});
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    // padding-top: rem($header_height);
    .b-accueil-intro_scrollTo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem($unit);
    }
    

    &.-bloc{
        .b-accueil-intro_scrollTo {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.b-accueil-intro_deco{
    &.-scrolling{
        svg{
            transition: 0.3s ease-out;
            transform: rotate(20deg);
        }
    }
}

.b-accueil-intro_deco1 {
    position: absolute;
    pointer-events: none;
    top: rem($unit * 2);
    right: rem(-$unit);
    transform: rotate(-28deg);
    svg {
        width: rem(220px);
    }
}

.b-accueil-intro_deco2 {
    position: absolute;
    pointer-events: none;
    bottom: rem(-$unit-small);
    left: 0;
    transform: rotate(54deg);
    svg {
        width: rem(220px);
    }
}

.b-accueil-intro_deco3 {
    position: absolute;
    pointer-events: none;
    top: rem($unit * 3);
    left: rem($unit);
    transform: rotate(-28deg);
    svg {
        width: rem(93px);
    }
}

.b-accueil-intro_deco4 {
    position: absolute;
    pointer-events: none;
    bottom: rem($unit-large);
    right: rem($unit-large);
    transform: rotate(54deg);
    svg {
        width: rem(93px);
    }
}
