.b-chiffres {
    position: relative;
    .b-chiffres_item{
        align-items: center;
        @media (max-width: $from-small){
            display: flex;
            gap: 0.5rem;

            div{
                width: 3rem;
            }
        }

        @for $i from 0 to 10 {
            &.b-chiffres_item#{$i}{
                transition: $speed2 $easing calc(0.15s * #{$i});
            }
        }
    }

    .b-chiffres_slider {
        min-width: 100%;
    }

    .b-chiffres_wrap {
        grid-gap: rem($unit);
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        .b-chiffres_item {
            &:nth-child(1) {
                width: 40%;
            }
            &:nth-child(2) {
                display: flex;
                grid-gap: 1.5rem;
                width: 33.3%;
            }
            &:nth-child(3) {
                display: flex;
                grid-gap: 1.5rem;
                width: 33.3%;
                margin-left: auto;
            }
            &:nth-child(4) {
                width: 40%;
            }
        }
    }

    .b-chiffres_deco {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 40%;
        top: 0;
    }

    @media(max-width: $from-medium){
        .b-chiffres_wrap {
            .b-chiffres_item {
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
                    width: 100%;
                }
            }
        }
    }

    @media(max-width: $from-small) {
        .b-chiffres_wrap {
            .b-chiffres_item {
                flex-direction: column;
                
                .u-bold{
                    &:last-of-type{
                        width: inherit;
                    }
                }
            }
        }
    }
}
