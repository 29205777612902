// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%; /* [2] */
    color: $color;
    font-family: $font-family;
    font-weight: $light;
    line-height: $line-height; /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    .has-scroll-smooth & {
        overflow: hidden;
    }

    &.search{
        #main-header{
            background-color: $black;
            height: 7rem;

            .c-header_container{
                border-bottom: inherit;
                padding-bottom: inherit;
            }
        }
    }
}

main {
  overflow: hidden;
}

.app {
  opacity: 0;
  transition: opacity 0.6s $easing;
}

.is-animated .app {
  opacity: 1!important;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $link-color;
    transition: 0.3s $easing;

    @include u-hocus {
        color: $link-hover-color;
    }
}

.e-page_intro_content{
    z-index: 1;
    position: relative;

    .e-page_intro_aside{

        .e-page_intro_aside_component{
            border-radius: rem(8px);
            box-shadow: $box-shadow;
            padding: 1rem;
    
            span{
                &.icon{
                    width: rem(50px);
                    min-width: rem(50px);
                    height: rem(50px);
                    border-radius: 50%;
                    background-color: $second;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        a{
            border-radius: rem(8px);
            .icon{
                width: rem(32px);
                min-width: rem(32px);
                height: rem(32px);
                border-radius: 50%;
                background-color: rgba($second, 0.72);
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    width: 0.8rem;
                }
            }

            &:hover{
                color: $second;
            }
        }
    }

    .o-container {
        max-width: 100%!important;
        padding: 0!important;
    }
}

.e-page_intro_container{
    .e-page_intro_title{
        
        position: relative;
        z-index: 2;

        .c-heading{
            display: flex;
            flex-direction: column;

            &::after{
                content: '';
                width: 100%;
                height: 2px;
                background-color: $primary;
                margin-top: 1rem;
            }
        }
    }
}

.pojo-a11y-toolbar-toggle{
    display: none;
}
