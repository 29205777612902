.c-search {
    position: relative;
    .c-search_nav_wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: calc(25vw + #{rem($unit)});
            height: 100%;
        }
    }
    .c-search_nav {
        padding: 2rem;
        border: 2px solid $grey;
        border-radius: 2rem;

        .c-search_nav_body {
            .c-search_nav_body_item {
                padding: 0.5rem 0 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $grey;
            }
        }
    }

    .c-search_content{
        border-bottom: 2px solid $grey;
    }
}

.search{
    .sub-nav-wrapper {
        width: fit-content;
        padding: 1rem;
        border-radius: rem(8px);
        border: 2px solid $second;
    }

    .search-items-wrapper{
        width: fit-content;
    }
}
