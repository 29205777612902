.b-avant-apres {
    .b-avant-apres_image-container {
        position: relative;
        img {
            display: block;
        }
    }
  
    .b-avant-apres_image-label {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $white;
        padding: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        opacity: 0;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0.3s 0.7s;
        -moz-transition: -moz-transform 0.3s 0.7s, opacity 0.3s 0.7s;
        transition: transform 0.3s 0.7s, opacity 0.3s 0.7s;
        &.is-hidden {
            visibility: hidden;
        }
    }
    .is-visible .b-avant-apres_image-label {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
  
    .b-avant-apres_resize-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        overflow: hidden;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .b-avant-apres_resize-img img {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 100%;
        width: auto;
        max-width: none;
    }
    .b-avant-apres_resize-img .b-avant-apres_image-label {
        right: auto;
        left: 0;
    }
    .is-visible .b-avant-apres_resize-img {
        width: 50%;
        /* bounce in animation of the modified image */
        -webkit-animation: b-avant-apres_bounce-in 0.7s;
        -moz-animation: b-avant-apres_bounce-in 0.7s;
        animation: b-avant-apres_bounce-in 0.7s;
    }
  
    @-webkit-keyframes b-avant-apres_bounce-in {
        0% {
            width: 0;
        }
        60% {
            width: 55%;
        }
        100% {
            width: 50%;
        }
    }
    @-moz-keyframes b-avant-apres_bounce-in {
        0% {
            width: 0;
        }
        60% {
            width: 55%;
        }
        100% {
            width: 50%;
        }
    }
    @keyframes b-avant-apres_bounce-in {
        0% {
            width: 0;
        }
        60% {
            width: 55%;
        }
        100% {
            width: 50%;
        }
    }
    .b-avant-apres_handle {
        position: absolute;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background: linear-gradient(to left top, #FFC215, #FF8515);
        display: flex;
        align-items: center;
        justify-content: center;
        /* center the element */
        left: 50%;
        top: 50%;
        margin-left: -1.5rem;
        margin-top: -1.5rem;
        border-radius: 50%;
        // background: $primary;
        cursor: move;
        // box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.6), inset 0 1px 0 rgba(255, 255, 255, 0.3);
        opacity: 0;
        // -webkit-transform: translate3d(0, 0, 0) scale(0);
        // -moz-transform: translate3d(0, 0, 0) scale(0);
        // -ms-transform: translate3d(0, 0, 0) scale(0);
        // -o-transform: translate3d(0, 0, 0) scale(0);
        // transform: translate3d(0, 0, 0) scale(0);
        color: $white;
        svg {
            width: rem($unit-small);
        }
    }
    .b-avant-apres_handle.draggable {
        /* change background color when element is active */
        // background-color: $primary;
    }
    .is-visible .b-avant-apres_handle {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        -moz-transform: translate3d(0, 0, 0) scale(1);
        -ms-transform: translate3d(0, 0, 0) scale(1);
        -o-transform: translate3d(0, 0, 0) scale(1);
        transform: translate3d(0, 0, 0) scale(1);
        -webkit-transition: -webkit-transform 0.3s 0.7s, opacity 0s 0.7s;
        -moz-transition: -moz-transform 0.3s 0.7s, opacity 0s 0.7s;
        transition: transform 0.3s 0.7s, opacity 0s 0.7s;
    }
  }
  