.c-button {
  padding: rem($unit-thin) rem($unit-tiny);
  border-radius: rem(5px);
  transition: $speed $easing;
  display: inline-flex;
  align-items: center;
  grid-gap: rem($unit-small);
  justify-content: space-between;

  .c-button_label {
    font-weight: $semibold;
  }

  .c-button_icon {
    width: rem(32px);
    min-width: rem(32px);
    height: rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: $speed $easing;
    svg {
      transition: $speed $easing;
    }
    &.-second {
      background-color: $second-opacity-60;
      color: $white;
    }
    &.-red {
      background-color: $red;
      color: $white;
    }
  }

  &.-primary {
    background-color: $primary;
    color: $grey;
  }

  &.-grey-light {
    background-color: $grey-opacity-16;
    color: $white;
  }

  &.-grey {
    background-color: $grey;
    color: $black;
  }

  &.-white {
    background-color: $white;
    color: $primary;
  }

  &.-square {
    width: rem(53px);
    height: rem(53px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.-round {
    width: rem(40px);
    height: rem(40px);
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    svg {
      max-height: rem(20px);
    }
    &.-big {
      width: rem(64px);
      height: rem(64px);
    }

    .c-button_label {
      &.-flap {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(calc(-100% - 0.5rem), -50%);
      }
    }
  }

  &.-outline {
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;
  }

  &.-nopadding {
    padding: 0;
  }

  @include u-hocus {
    .c-button_label {
    }
    .c-button_icon {
      svg {
        transform: scale(0.8);
      }
      &.-second {
        background-color: $second;
      }
    }

    &.-primary {
      background-color: $primary-dark;
    }

    &.-grey-light {
      background-color: $grey-light-opacity-32;
    }

    &.-white {
      background-color: $primary-dark;
      color: $white;
    }

    &.-outline {
      background-color: $primary;
      color: $white;
    }
  }
}
