.c-image {
    position: relative;
    border-radius: rem(8px);
    overflow: hidden;
    .c-image_legende {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: rem($unit-thin);
        text-shadow: 1px 1px 2px $black;
        font-size: rem($font-size-h6);
        line-height: 1;
        z-index: 5;
        &.-top-right {
            top: 0;
            right: 0;
            left: auto;
            bottom: auto;
        }
    }
}