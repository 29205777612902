.c-figure {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.-contain{
    img{
      object-fit: contain;
      width: fit-content;
    }
  }
}
