.c-service {
    // background-color: $grey;
    position: relative;
    z-index: 1;

    background: linear-gradient(45deg, $primary-dark, $primary-light);

    .c-service_header{
        position: relative;

        .o-container{
            z-index: 2;
            position: relative;

            figure{
                z-index: -1;
            }
        }

        .c-header{
            .o-layout{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
    
                .o-layout_item{
                    &:not(.c-header_height){
                        width: 100% !important;
                    }
                }
    
                // &::after{
                //     content: '';
                //     width: 100%;
                //     height: 2px;
                //     background-color: $grey;
                //     position: absolute;
                //     bottom: -2rem;
                //     left: 0;
                // }
            }
        }

        header{
            .c-header_logo{
                .logo{
                    width: 7rem;
                }
            }

            .c-header_height{
                width: fit-content !important;
                margin-right: 3rem;
            }
        }

        .c-service_header_nav{
            padding-top: calc(2rem + #{rem($header-height)});
            display: flex;
            align-items: center;
            gap: 2rem;
            flex-wrap: wrap;
            
            .c-service_header_bd{
                display: flex;
                align-items: center;
                gap: 0.5rem;
                text-transform: uppercase;
                font-weight: $semibold;
                opacity: 0.8;
                font-size: 14px;
    
                a{
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }

    .c-service_bg{
        z-index: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        height: calc(100% + 167px);
        padding-top: $header-height;
    }

    .c-service_header_desc_content{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .c-service_header_desc_content_wrap{
            &:first-of-type{
                padding-left: 3rem;
                margin-bottom: 3rem;
                border-left: 1px solid $grey;
            }

            &:last-of-type{
                display: flex;
                position: relative;
                top: 30px;
            }
        }

        figure{
            position: absolute;
            right: 5rem;
            bottom: 0;
            height: rem(380px);
        }
    }
    
    &.-page{
        .c-service_header{
            min-height: rem(380px);
        } 
        
        .c-service_header_desc_content{
            .c-service_header_desc_content_wrap:last-of-type{
                padding-left: 2rem;
                justify-content: flex-start;
            }

            figure{
                position: absolute;
                bottom: 0;
                right: 5rem;
                height: rem(380px);
            }
        } 
    }

    @media(max-width: $from-small){
        .c-service_header_desc {
            .-h1 {
                font-size: 2.5rem;
            }

            .c-service_header_desc_content{
                .c-service_header_desc_content_wrap{
                    width: 80% !important;
                }

                figure{
                    display: none;
                }
            }
        }
    }
}

.c-service_bind {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;

    .c-service_bind_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .-h2,
    .c-service_bind_container {
        z-index: 1;
        position: relative;
    }

    &::before {
        content: '';
        z-index: 0;
        width: 100%;
        height: calc(100% + #{$unit-large} + 58px);
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: white;
    }
}

.c-service_liens_container{
    z-index: 1;
    position: relative;

    .c-service_liens{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 1rem;

        a{
            width: rem(230px);
            height: rem(72px);
            transition: $speed $easing;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-light;
            color: white;
            border-radius: rem(8px);
            padding: 0 0.5rem;
            text-align: center;

            &:hover, &.-is-active{
                background-color: $primary-dark;
            }
        }
    }

    &.-content{
        &::after{
            content: '';
            position: absolute;
            width: rem(94px);
            height: rem(94px);
            background-color: $grey;
            left: 50%;
            bottom: 0;
            border-radius: rem(8px);
            transform: translate(-50%, 15%) rotate(45deg);
            z-index: -1;
        }
    }

    @media(max-width: $from-small){
        .c-service_liens {
            justify-content: center;
        }
    }
}
