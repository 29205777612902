.c-footer {
  z-index: 0;
  position: relative;

  &.-accueil {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      background: linear-gradient(
        to bottom,
        rgba(#fff, 0) 50%,
        rgba(white, 100%) 50%,
        rgba($grey, 0) 100%
      );
      top: -100%;
      height: rem(600px);
      width: 100%;
      pointer-events: none;
    }

    &::after {
      height: 150%;
    }
  }
  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    background: radial-gradient($primary-light, $primary-dark);
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .c-footer_link {
    color: $grey-light-opacity-62;
    &:hover {
      color: $grey-light;
    }
  }

  .c-footer_nav_wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem($unit-tiny);
  }

  @media (max-width: $from-medium) {
    .c-footer_nav_wrap {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: $from-small) {
    .c-footer_nav_wrap {
      grid-template-columns: 1fr;
    }

    .u-text-right {
      text-align: inherit !important;
      margin-top: 0.5rem;
    }
  }
}
