.c-navbloc {
    .c-navbloc_container {
        display: grid;
        grid-gap: 0.5rem;
        justify-content: flex-start;
    }
    .c-navblock_link {
        color: $grey;
        text-transform: uppercase;
        font-weight: $bold;
        font-size: rem($font-size - 2px);
        &:hover, &.is-active {
            color: $black;
        }
    }
}

body {
    &[data-bg="-black"] {
        .c-navbloc {
            .c-navblock_link {
                color: rgba($white, 0.5);
                &:hover, a.is-active {
                    color: $white;
                }
            }
        }
    }
  }