.b-cards{
    z-index: 2;
    position: relative;
    &.-listing{
        .o-layout_item{
            @media (min-width: $from-medium){
                &:nth-of-type(3n + 2){
                    transform: translateY(6rem);
                }    
            }
        }

        @media (max-width: $from-medium){
            .o-layout_item{
                &:nth-of-type(2), &:nth-of-type(5){
                    transform: inherit;
                }

                .c-card{
                    width: initial;
                    height: 21rem;
                }
            }
        }
    }

    .b-cards_content{
        gap: 2rem 0;
    }

    .b-cards_slider{
        display: flex;
        flex-direction: column;

        &.b-cards_slider_top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .c-slider_nav{
                position: absolute;
                right: -5rem;
                top: 8rem;    
                
                &:first-of-type{
                    left: -5rem;
                }
            }

            .b-cards_slider_component{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 90%;

                .b-cards_slider_trinity{
                    height: 4rem;
                    width: 4rem;
                    background: white;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                } 
            }
        }
    }

    &.-slider{
        &.padding{
            padding-bottom: 25rem !important;
        }

        .b-cards_border{
            margin-bottom: 2rem;
            border-bottom: 1px solid #FF8515;
            width: 100%;
        }

        // .b-cards_slider_left{
        //     position: absolute;
        //     top: 50%;
        //     bottom: 50%;
        //     left: -8rem;
        // }

        // .b-cards_slider_right{
        //     position: absolute;
        //     top: 50%;
        //     bottom: 50%;
        //     right: -10rem;
        // }
    }
}

// .b-cards_slider_left, .b-cards_slider_right{
//     display: flex;
//     align-items: center;
//     padding: 0 1rem 0 3rem;
//     z-index: 2;
//     cursor: pointer;
// }

// .b-cards_slider_right{
//     padding: 0 5rem 0 0;
// }

.b-cards_slider_left_icon{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background: linear-gradient(to right top, $primary, $second);
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: rem(17px);
        transform: rotate(180deg);
    }
}

.b-cards_slider_right_icon{
    height: 3rem;
    min-width: 3rem;
    border-radius: 50%;
    background: linear-gradient(to left top, $primary, $second);
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: rem(17px);
    }
}