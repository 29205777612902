.c-slider{
    &.-slide-hidden{
        overflow: hidden;
    }
    .-offset {
        margin-right: rem(-$unit);
    }
    &:not(.-vertical) {
        .swiper-slide {
            height: auto!important;
        }
    }
    &.-vertical {
        .c-slider_container {
            max-height: rem(500px);
        }
    }
    &.-auto .swiper-slide {
        width: auto!important;
    }
}


.c-slider_container{
    overflow: hidden;

    @media(max-width: $from-medium){
        max-height: 40rem !important;

        .swiper-wrapper{
            height: inherit;
        }
    }

    @media(max-width: $from-small) {
        max-height: 50rem !important;
        margin-top: 2rem;
    }
}

.swiper-button-disabled{
    opacity: 0.5;
}

.swiper-navigation {
    display: flex;
    grid-gap: rem($unit-thin);
}

.c-slider_nav {
    display: flex;
    align-items: center;
    grid-gap: rem($unit-small);
    .c-button {
        padding: 0;
        &.-prev {
            transform: rotate(-135deg);
        }
        &.-next {
            transform: rotate(45deg);
        }
    }
    .c-slider_indicator {
        flex: 2;
        border-bottom: 1px solid $primary;
        position: relative;
        .progress-bar {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4rem;
            background-color: $second;
            height: 5px;
            border-radius: 3px;
            z-index: 2;
            max-width: 100%;
            transition: $speed $easing;
        }
    }

    &.-vertical {
        flex-direction: column;
        height: 100%;
        .c-button {
            &.-prev {
                transform: rotate(-45deg);
            }
            &.-next {
                transform: rotate(135deg);
            }
        }
        .c-slider_indicator {
            border-right: 1px solid $primary;
            .progress-bar {
                left: 50%;
                top: 0;
                height: 4rem;
                width: 5px;
                transform: translateX(-50%);
                max-height: 100%;
                max-width: none;
            }
        }
        .swiper-navigation {
            flex-direction: column;
        }
    }
}

.swiper-button-disabled {
    opacity: 0.3;
}

.swiper-slide{
    transition-duration: 0.5s !important;

    &.swiper-slide-active{
        .b-chiffres_item{
            opacity: 1;
        }
    }

    .b-chiffres_item{
        opacity: 0;
    }
}
