// .b-onglet{
//     overflow: hidden;
//     .o-container{
//         display: flex;
//         flex-direction: column;
//     }

//     .b-onglet_nav{
//         display: flex;
//         align-items: flex-end;
//         justify-content: flex-start;
//         width: 100%;
//         flex-wrap: wrap;
//         gap: 1rem;

//         .b-onglet_nav_title{
//             transform: translateY(2px);
//             color: $primary;
//             margin-bottom: inherit;
//             cursor: pointer;

//             p{
//                 padding: 1rem;
//                 border-radius: 2rem;
//                 background: lightgray;
//                 transition: 0.3s ease-out;

//                 &:hover{
//                     background: $second;
//                 }
//             }
//         }

//         .clicked{
//             background-color: $second !important;
//             color: white;
//         }
//     }

//     .b-onglet_container{
//         // display: flex;
//         // width: max-content;
//         overflow: hidden;

//         display: grid;

//         .b-onglet_text{
//             // padding-right: 22vw;
//             // width: 100vw;
//             transition: 0.5s ease;

//             grid-row: 1;
//             grid-column: 1;

//             div{
//                 columns: 2;
//                 column-gap: rem($unit);
//                 // width: 75%;

//                 span{
//                     display: flex;
//                     flex-direction: column;
//                     margin-bottom: inherit;
//                 }

//                 @media (max-width: $from-medium){
//                     columns: 1;
//                     column-gap: inherit;
//                     // width: 90%;
//                 }
//             }

//             p{
//                 margin: 0 0 1.3rem 0;
//             }

//             @media (max-width: 680px){
//                 padding-right: 5rem;

//                 p{
//                     columns: inherit;
//                 }
//             }

//             // @media (min-width: 1400px){
//             //     padding-right: 20vw;
//             // }

//             // @media (min-width: 1800px){
//             //     padding-right: 30vw;
//             // }
//         }
//     }

//     @media(max-width: $from-small){
//         .b-onglet_nav {
//             justify-content: center;
//             align-items: center;
//         }
//     }
// }

.b-onglet{
    overflow: hidden;
    .o-container{
        display: flex;
        flex-direction: column;
    }

    .c-slider {
        width: 100%;
    }

    .b-onglet_nav{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        padding-top: rem($unit-small);

        .b-onglet_nav_title{
            cursor: pointer;
            padding: rem($unit-tiny);
            width: calc(100% - (#{rem($unit-tiny)} * 2));
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: rem(45px);
            background: lightgray;
            transition: $speed $easing;

            &:hover, &.is-active{
                background: $second;
                color: $white;
            }
        }
    }

    .b-onglet_container{
        overflow: hidden;

        display: grid;

        .b-onglet_text{
            transition: 0.5s ease;

            grid-row: 1;
            grid-column: 1;

            div{
                columns: 2;
                column-gap: rem($unit);

                span{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: inherit;
                }

                @media (max-width: $from-medium){
                    columns: 1;
                    column-gap: inherit;
                }
            }

            p{
                margin: 0 0 rem($unit-tiny) 0;
            }

            @media (max-width: 680px){
                padding-right: rem($unit);

                p{
                    columns: inherit;
                }
            }
        }
    }
}
