.b-gravityform {
    input:not([type="submit"]), textarea {
        @extend .c-form_input;
    }
    input[type="submit"] {
        @extend #{'.c-button', '.-primary'};
        width: auto;
    }
}

.gform-field-label {
    // margin-bottom: rem($unit-tinier);
}

.ginput_container_select {
    @extend .c-form_select;
    select {
        @extend .c-form_select_input;
    }
}

.ginput_address_country {
    @extend .c-form_select;
    &::after {
        top: auto;
        right: 0.9804%;
        bottom: 0;
        height: 51px;
    }
    select {
        @extend .c-form_select_input;
    }
}

.gform_heading {
    display: none;
    .gform_title {
        @extend #{'.c-heading', '.-h4'};
    }
}

.gfield-choice-input {
    appearance: auto;
    width: auto;
    // margin-right: rem($unit-tiny);
}

.gform_wrapper.gravity-theme .gfield input.medium, .gform_wrapper.gravity-theme .gfield select.medium {
    @media (max-width: $to-large) {
        width: 100%!important;
    }
}

.gfield--type-repeater {
    // margin-top: rem($unit-small);
}

.gfield_repeater_wrapper {
    // padding: rem($unit-small);
    background-color: rgba($second, 0.32);
    border-radius: rem(16px);
}

.gform_wrapper.gravity-theme .gfield_repeater_cell label {
    color: inherit!important;
    font-size: inherit!important;
    font-weight: inherit!important;
    padding-top: inherit!important;
}

.gform-theme-button {
    background-color: $white!important;
    color: $black!important;
    // padding: rem($unit-tinier) rem($unit-small)!important;
    height: auto!important;
    &:hover {
        background-color: $black!important;
        color: $white!important;
    }
}