.b-etapes {
    .b-etapes_item{
        &:not(:last-of-type){
            margin-bottom: 3rem;
        }
        .c-heading{
            margin-bottom: 0;
        }
    }
    .b-etapes_item_head{
        display: grid;
        grid-template-columns: 5.5rem 1fr;
        grid-gap: 1rem;

        .b-etapes_item_head_count{
            height: 5.5rem;
            width: 5.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgba($color: $second, $alpha: 0.15);
        }

        @media (max-width: 400px){
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            grid-template-columns: inherit;
            grid-gap: inherit;

            .b-etapes_item_head_count{
                margin-bottom: 2rem;
            }
        }
    }
}
