
.c-bloc-title_component{
    position: relative;
    display: flex;
    align-items: center;
}

.c-bloc-title_deco{
    z-index: -1;
    position: absolute;
    left: 0;
}
