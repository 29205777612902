.b-acces-rapide {
    height: rem(270px);

    .b-acces-rapide_wrap {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: rem($unit-tiny);

        a{
            span{
                font-weight: $semibold;
            }
        }
    }

    &.-radient{
        background: linear-gradient(45deg, $primary-dark, $primary-light);
        // &::before{
        //     content: '';
        //     background: radial-gradient(#178891, #053D65);
        //     width: 100%;
        //     height: calc(100% + 12.5rem);
        //     position: absolute;
        //     top: rem(-200px);
        //     left: 0;
        //     z-index: -1;
        // }
        // &::after{
        //     content: '';
        //     background: linear-gradient(to bottom, rgba(#dcf6f7, 0),#dcf6f7);
        //     width: 100%;
        //     height: 88%;
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     z-index: -1;
        // }
    }

    @media(max-width: $from-medium){
        .b-acces-rapide_wrap {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media(max-width: $from-tiny) {
        margin-bottom: 4rem;
        
        .b-acces-rapide_wrap {
            grid-template-columns: 1fr;
        }

        &.-radient{
            &::after{
                bottom: -4rem;
            }
        }
    }
}
