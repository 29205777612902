// ==========================================================================
// Utilities / Helpers
// ==========================================================================

// Layout
// ==========================================================================
.u-clearfix {
    @include u-clearfix;
}

// Decorative
// =============================================================================
.u-truncate {
    @include u-truncate;
}

.u-bold {
  font-weight: $bold;
  font-family: $font-myriad-pro-semibold;
}
.u-semibold {
    font-weight: $semibold;
}
.u-medium {
  font-weight: $medium;
}
.u-light {
  font-weight: $light;
}

.u-uppercase {
    text-transform: uppercase;
}

.u-h-100 {
    height: 100%;
}

.u-w-100 {
    width: 100%;
}

.u-margin-top-auto {
    margin-top: auto;
}

.u-rotate-45 {
    transform: rotate(45deg);
}
.u-rotate-225 {
    transform: rotate(225deg);
}

.u-overflow-hidden {
    overflow: hidden;
}

.u-radius {
    border-radius: 8px;
}

.u-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &.-middle {
        align-items: center;
    }

    &.-bottom {
        align-items: flex-end;
    }

    &.-column {
        flex-direction: column;
    }

    &.-end {
        justify-content: flex-end;
    }

    &.-center {
        justify-content: center;
    }

    &.-space {
        justify-content: space-between;
    }
    .-grow{
        flex-grow: 1;
    }
    &.-gap{
        gap: $unit;
    }
    &.-gap-small{
        gap: $unit-small;
    }
    &.-gap-tiny{
        gap: $unit-tiny;
    }
    &.-gap-tinier {
        gap: $unit-tiny / 2;
    }
    &.-wrap{
        flex-wrap: wrap;
    }
}

.u-grid{
    display: grid;
    margin: 0 auto;

    &.-t-c-4{
        grid-template-columns: repeat(4, 1fr);
    }

    &.-gap-tiny{
        gap: 1rem;
    }
}

//  Visibility / Display
// ==========================================================================
[hidden][aria-hidden="false"] {
    position: absolute;
    display: inherit;
    clip: rect(0, 0, 0, 0);
}

[hidden][aria-hidden="false"]:focus {
    clip: auto;
}

// .u-block {
//     display: block;
// }

// /**
//  * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
//  * inline-block will display at its default size, and not limit its width to
//  * 100% of an ancestral container.
//  */
// .u-inline-block {
//     display: inline-block !important;
//     max-width: 100%; /* 1 */
// }

// .u-inline {
//     display: inline !important;
// }

// .u-table {
//     display: table !important;
// }

// .u-tableCell {
//     display: table-cell !important;
// }

// .u-tableRow {
//     display: table-row !important;
// }

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-screen-reader-text {
    @include u-accessibly-hidden;
}

@media not print {
    .u-screen-reader-text\@screen {
        @include u-accessibly-hidden;
    }
}

/*
 * Extends the `.screen-reader-text` class to allow the element
 * to be focusable when navigated to via the keyboard.
 *
 * @link https://www.drupal.org/node/897638
 * @todo Define styles when focused.
 */
.u-screen-reader-text.-focusable {
    @include u-accessibly-focusable;
}
