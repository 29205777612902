.c-card {

  &.-listing{
    .c-card_wrap {
      background-color: $grey-light;
      border-radius: rem(8px);
      overflow: hidden;
      box-shadow: $box-shadow;
    }
    .c-card_image {
      height: rem(280px);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(transparent 200px, $grey-light);
        z-index: 1;
      }
    }
    .c-card_content {
      position: relative;
      z-index: 2;
      padding: rem($unit-tiny);
      height: calc(100% - #{rem(280px)});
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &.-equipe{
    .c-card_content {
      position: relative;
      z-index: 2;
      overflow: hidden;
      height: rem(170px);
      display: flex;
      border-radius: rem(8px);
    }

    .c-card_image{
      min-width: rem(120px);
    }

    @media(max-width: $from-tiny){
      .c-card_image{
        display: none;
      }
    }
  }

  &.-page {
    border-radius: rem(8px);
    &:hover {
      .c-button {
        .c-button_icon {
          &.-second {
            background-color: $second;
          }
        }
      }
    }
  }
  &.-actualite {
    .c-card_wrap {
      border-radius: rem(8px);
      overflow: hidden;
      display: grid;
      grid-template-columns: rem(240px) 1fr;
      box-shadow: $box-shadow;
      background-color: $white;
      height: 100%;
    }
    .c-card_image {
      overflow: hidden;
      img {
        transition: $speed $easing;
      }
    }
    .c-card_content {
      padding: rem($unit-tiny);
      display: flex;
      flex-direction: column;
    }
    .c-card_body {
      margin-bottom: auto;
    }

    .c-card_foot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c-button {
        padding: 0;
      }
    }

    &:hover {
      .c-card_image {
        img {
          transform: scale(1.05);
        }
      }
      .c-button {
        .c-button_icon {
          &.-second {
            background-color: $second;
          }
        }
      }
    }

    @media(max-width: $from-tiny){
      .c-card_wrap {
        grid-template-columns: 1fr;
      }
    }
  }

  &.-offre-emploi{
    border-radius: rem(8px);
    background-color: white;
  }

  &.-equipe{
    
  }

  &.-service {
    &:hover {
      .c-button {
        .c-button_icon {
          &.-second {
            background-color: $second;
          }
        }
      }
    }
  }
}

