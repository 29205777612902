// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================

:root {
  --color-primary: #00798e;
  --color-second: #f88b64;
  --color-second-opacity-60: rgba(248, 139, 100, 0.6);
  --color-second-opacity-72: rgba(248, 139, 100, 0.72);
  --color-white: #ffffff;
  --color-black: #133d58;
  --color-black-opacity-62: rgba(19, 61, 88, 0.62);
  --color-primary-light: #178891;
  --color-primary-dark: #053d65;
  --color-primary-dark-opacity-62: rgba(5, 61, 101, 0.62);
  --color-header: #133d58;

  --color-grey: #dcf6f7;
  --color-grey-opacity-16: rgba(220, 246, 247, 0.16);
  --color-grey-light: #dcf6f7;
  --color-grey-light-opacity-32: rgba(220, 246, 247, 0.32);
  --color-grey-light-opacity-62: rgba(220, 246, 247, 0.62);
  --color-black-overlay-light: rgba(0, 0, 0, 0.5);

  --color-shadow: #2d3371;
  --color-box-shadow: 0 20px 60px rgba(21, 51, 113, 0.16);
}

$white: var(--color-white);
$black: var(--color-black);
$black-opacity-62: var(--color-black-opacity-62);
$primary: var(--color-primary);
$second: var(--color-second);
$second-opacity-72: var(--color-second-opacity-72);
$second-opacity-60: var(--color-second-opacity-60);
$primary-light: var(--color-primary-light);
$primary-dark: var(--color-primary-dark);
$primary-dark-opacity-62: var(--color-primary-dark-opacity-62);
$header: var(--color-header);

$green: #77c483;
$red: #e86161;

$grey: var(--color-grey);
$grey-light: var(--color-grey-light);
$grey-light-opacity-32: var(--color-grey-light-opacity-32);
$grey-light-opacity-62: var(--color-grey-light-opacity-62);
$grey-opacity-16: var(--color-grey-opacity-16);
$black-overlay-light: var(--color-black-overlay-light);

$shadow: var(--color-shadow);
$box-shadow: var(--color-box-shadow);

// Specific
// =============================================================================
// Link
$link-color: $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color: $primary;
$selection-background-color: #ffffff;

// Social Colors
// =============================================================================
$facebook-color: #3b5998;
$instagram-color: #e1306c;
$youtube-color: #cd201f;
$twitter-color: #1da1f2;
