.c-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transform: translateY(calc(-100% - #{$header_height}));
  transition: transform 1s $easing2;
  height: 100vh;
  overflow: hidden;

  .c-nav_wrap {
    overflow-x: auto;
    height: 100vh;

    .o-container {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: "";
      background: radial-gradient($primary-light, $primary-dark 50%);
      overflow-x: auto;
      height: 200vh;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .c-nav_primary {
    display: flex;
    flex-direction: column;
    grid-gap: 2.5rem;
    transition: 0.3s $easing;
    transition-delay: 0.3s;
    color: $white;
    padding-right: 2rem;
    border-right: 2px solid white;
    z-index: 1;

    .c-nav_primary_link {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .c-nav_primary_link_component {
        cursor: pointer;
        z-index: 1;
        position: relative;
        div {
          position: relative;

          span {
            font-size: 2rem;
            transition: color $speed $easing, width $speed $easing,
              transform $speed $easing;
          }
        }

        &.-sub-active {
          div {
            span {
              color: $second;
            }
          }
          .c-nav_primary_link_component_icon {
            span {
              &:nth-of-type(1) {
                width: 2rem;
                background-color: $second;
              }
              &:nth-of-type(2) {
                border-top: 2px solid $second;
                border-right: 2px solid $second;
                transform: rotate(45deg) translate(17px, -10px);
              }
            }
          }
        }

        .c-nav_primary_link_component_icon {
          display: flex;
          align-items: center;
          position: relative;
          height: 20px;
          width: 20px;
          span {
            &:nth-of-type(1) {
              height: 2px;
              width: rem(12px);
              background-color: white;
              position: absolute;
              top: 50%;
              left: -3px;
              transform: translateY(-50%);
            }
            &:nth-of-type(2) {
              width: rem(10px);
              height: rem(10px);
              border-top: 2px solid white;
              border-right: 2px solid white;
              transform: rotate(45deg) translate(3px, 4px);
              position: absolute;
              top: 0;
            }
          }
        }

        &:hover {
          div {
            span {
              color: $second;
            }
          }

          .c-nav_primary_link_component_icon {
            span {
              &:nth-of-type(1) {
                background-color: $second;
              }

              &:nth-of-type(2) {
                border-top: 2px solid $second;
                border-right: 2px solid $second;
              }
            }

            svg {
              path {
                fill: $second;
              }
            }
          }
        }
      }
    }

    .c-nav_primary_cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      a {
        height: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.5rem;
        background-color: $grey-opacity-16;
        border-radius: rem(8px);

        .c-nav_primary_cards_title {
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          gap: 0.5rem;

          span {
            width: rem(32px);
            min-width: rem(32px);
            height: rem(32px);
            border-radius: 50%;
            background-color: rgba($color: $second, $alpha: 0.62);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .c-nav_secondary {
    z-index: 0;
    position: relative;

    &.-mobile {
      display: none;
      flex-direction: column;
      gap: 1.5rem;
      height: 0;
      overflow: hidden;

      &.-is-mobileActive {
        margin: 1rem 0 0.5rem 1rem;
        height: 100%;
        transition: $speed $easing;
      }

      .c-nav_secondary_link {
        color: $grey;

        .-h5,
        .-h6,
        &.-h5 {
          transition: $speed $easing;
          &:hover {
            color: $second;
          }
        }

        .c-nav_secondary_link_under {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          height: 0;
          overflow: hidden;

          &.-is-mobileActive2 {
            margin: 0.75rem 0 0.25rem 1rem;
            height: 100%;
          }

          a {
            color: $grey;
            text-transform: capitalize;
          }
        }
      }
    }

    .c-nav_secondary_component {
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;

      .c-nav_secondary_link {
        transform: translateX(calc(-100% - 2rem));
        transition: color $speed $easing;
        color: $grey-light-opacity-62;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .c-nav_secondary_link_under {
          overflow: hidden;
          height: 0;
          transition: $speed $easing;

          a {
            color: $grey-light-opacity-62;
          }
        }

        &.-is-deployed2 {
          .c-nav_secondary_link_under {
            height: fit-content;
            margin-top: 0.75rem;
          }
          .c-nav_secondary_link_plus {
            &::after {
              transform: scaleY(0);
            }
          }
        }

        &:hover {
          &.-h5 {
            color: $grey;
          }
        }
      }

      &.is-deployed {
        z-index: 1;
        .c-nav_secondary_link {
          transform: translateX(0);
          @for $i from 0 through 20 {
            &.c-nav_secondary_link#{$i} {
              transition: transform $speed2 $easing 0.05s * $i,
                color $speed $easing;
            }
          }

          &.c-nav_secondary_link .-h5,
          .c-nav_secondary_link_under .-h6 {
            &:hover {
              color: $grey;
            }
          }
        }
      }
    }
  }

  .c-nav_primary,
  .c-nav_secondary,
  .c-nav_thirdly {
    opacity: 0.2;
    transition: opacity 0.5s $easing2;
  }

  .c-nav_primary_container {
    display: flex;
    margin-top: $header-height;
    padding-top: 3rem;

    .c-nav_primary_icon {
      transform: translateY(-5rem);
      transition: 0.6s $easing 0.4s;
      svg {
        width: 5rem;
      }
    }
  }

  .b-accueil-intro_deco {
    transition: 0.8s $easing 0.6s;
    transform: translateY(8rem);
  }

  @media (max-width: $from-big) {
    .c-nav_secondary {
      display: none;

      &.-mobile {
        display: flex;
      }
    }

    .c-nav_primary {
      width: 50% !important;
      .c-nav_primary_link {
        .c-nav_primary_link_component {
          .c-nav_primary_link_component_icon {
            display: none;
          }
        }
      }
    }

    .c-nav_thirdly {
      width: 50% !important;
    }
  }

  @media (max-width: $from-medium) {
    .c-nav_primary_container {
      .c-nav_primary {
        width: 60% !important;

        .c-nav_primary_cards_title {
          font-size: 0.9rem;
        }
      }

      .c-nav_thirdly {
        width: 40% !important;
      }
    }
  }

  @media (max-width: $from-small) {
    .c-nav_primary_container {
      flex-direction: column;

      .c-nav_primary,
      .c-nav_thirdly {
        width: 100% !important;
      }

      .c-nav_primary {
        border-right: inherit;
      }

      .c-nav_primary_icon {
        display: none;
      }
      .c-nav_thirdly {
        margin-top: 2rem;

        .c-nav_thirdly_top,
        .c-nav_thirdly_bottom {
          width: 100% !important;
          margin-top: 2rem;
        }
      }
    }
  }
}

.c-nav_secondary_link_plus {
  position: relative;
  width: rem(12px);
  height: rem(12px);

  &.-mobile {
    display: none;
  }

  &::before {
    content: "";
    width: rem(12px);
    height: rem(2px);
    background-color: $grey;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    content: "";
    width: rem(2px);
    height: rem(12px);
    background-color: $grey;
    position: absolute;
    right: 5px;
    top: 0;
    transition: $speed $easing;
  }

  @media (max-width: $from-big) {
    &.-mobile {
      display: flex;
    }
  }
}

.-sub-active {
  z-index: 2 !important;
  position: relative;
  .c-nav_primary_link_title {
    .c-nav_secondary_link_plus {
      &::after {
        transform: scaleY(0);
      }
    }
  }
}

.-sub-active2 {
  .c-nav_secondary_link_plus {
    &::after {
      transform: scaleY(0);
    }
  }
}

.has-navOpen {
  .c-nav {
    transform: translateY(0);
    .c-nav_primary,
    .c-nav_secondary,
    .c-nav_thirdly {
      opacity: 1;
      transition: opacity 0.5s $easing2 0.5s;
    }
    .c-nav_shop {
      opacity: 1;
      transform: translateY(0);
    }
    .c-nav_image-bg {
      transform: translateY(0);
    }

    .c-nav_primary_container {
      .c-nav_primary_icon {
        transform: translateY(0);
      }
    }

    .b-accueil-intro_deco {
      transform: translateY(0);
    }
  }
}
