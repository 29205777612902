.c-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $speed $easing;
    opacity: 0;
    visibility: hidden;
    z-index: 11;
    padding: rem($unit-small);
    
    &::before{
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba($color: $primary, $alpha: 0.72);
      z-index: 0;
    }
    .c-modal_bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // background-color: $blue-overlay-light;

    }
    .c-modal_close {
      position: absolute;
      top: rem($unit);
      left: 50%;
      cursor: pointer;
      z-index: 2;
      height: 3rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .c-button{
        width: 100%;
        padding: inherit;
        height: 100%;
        border: 2px solid $grey;
      }
    }
    img {
      position: relative;
    }
    .c-modal_wrap {
        width: 65vw;
        z-index: 1;
        position: relative;

        input{
          width: rem(318px);

          &::placeholder{
            font-style: italic;
            color: white;
          }
        }
    }
    &.is-open {
      opacity: 1;
      visibility: visible;
    }

    .c-modal_search {
        color: $white;
        position: relative;
        .c-form_label {
            font-size: $font-size-h1;
            opacity: 1;
            left: 0;
        }
        .c-form_input {
            border-bottom: 4px solid $grey;
            font-size: $font-size-h3;
            line-height: 1;
            padding: 0.7rem 0;
            padding-right: rem(50px);
            height: rem(100px);
            color: $grey;
        }
        .c-modal_search_button {
            position: absolute;
            right: 0;
            bottom: calc(4px + 1rem);
            svg {
                width: rem(40px);
            }
        }
        .c-form_item.is-active label {
            transform: translateY(-100%);
        }
    }

    .cross{
      position: absolute;
      top: 3rem;
      right: 3rem;
      width: rem(22px);
      height: rem(22px);
      cursor: pointer;

      span{
        width: 2rem;
        height: 3px;
        background-color: white;
        position: absolute;
        top: 0;

        &:first-of-type{
          left: 0;
          transform-origin: left;
          transform: rotate(45deg);
        }
        &:last-of-type {
          right: 0;
          transform-origin: right;
          transform: rotate(-45deg);
        }
      }
    }
}
