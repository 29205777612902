.b-organigramme{
    .b-organigramme_component{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    @media(max-width: $from-big){
        .b-organigramme_component {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media(max-width: $from-small){
        .b-organigramme_component{
            grid-template-columns: 1fr;
        }
    }
}
