.c-bloc{
    position: relative;
    padding: rem($unit);

    &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100vw;
        box-shadow: 0px 5px 30px rgba(black, 0.10);
        border-radius: 1rem 0 0 1rem;
        background-color: white;
        left: 0;
        top: 0;
        z-index: -1;
    }

    @media (max-width: $from-small){
        padding: rem($unit-small)
    }
}
