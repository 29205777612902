.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-black {
  color: $black;
}
.u-c-white {
  color: $white;
}
.u-c-grey-light {
  color: $grey-light;
}
.u-c-grey-extra-light {
  color: $grey-light-opacity-62;
}
.u-c-primary-dark-light {
  color: $primary-dark-opacity-62;
}
.u-c-primary-black-light {
  color: $black-opacity-62;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-second-light {
  background-color: $second-opacity-72;
}
.u-bg-black {
  background-color: $black;
}
.u-bg-primary-dark {
  background-color: $primary-dark;
}
.u-bg-white {
  background-color: $white;
}
.u-bg-grey-light {
  background-color: $grey-light;
}
