.c-accordion_item {
  border-bottom: 1px solid $grey;
  padding: 1rem 0;
  &.-offre{
    padding: 0;

    .c-accordion_header {
      .c-heading {
        padding-right: inherit;
        min-width: rem(130px);
      }
    }
  }
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .c-heading {
      padding-right: 50px;
    }
  }
  .c-accordion_plus {
    position: relative;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: $second;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    margin-left: 0.66667rem;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 3px solid $white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      margin: auto;
    }
    &::after {
      border-left: 3px solid $white;
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
    }
    .icon {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        max-width: 50px;
      }
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.is-open {
    .c-accordion_header{
      border-bottom: 2px solid $second;
      margin-bottom: 2rem;

      div{
        color: $second;
      }
    }
    .c-accordion_main {
      height: auto;
      padding-bottom: 2rem;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
  }
}
