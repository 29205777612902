.b-fichiers {
    .b-fichiers_container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        border-radius: rem(9px);
        box-shadow: 0px 20px 60px rgba($shadow, 0.16);

        .b-fichiers_title{
            font-size: 14px;
            font-weight: $semibold;
        }

        .b-fichiers_link{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.6rem 0.8rem;
            border-radius: rem(9px);
        }

        .b-fichiers_wrap{
            flex-grow: 1;
            gap: 1rem;
            display: flex;
            flex-direction: column;

            .b-fichiers_icon{
                width: rem(32px);
                min-width: rem(32px);
                height: rem(32px);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    width: 0.8rem;
                }
            }
        }
    }

    @media(max-width: $from-small){
        .b-fichiers_container{
            flex-direction: column;
        }
    }

    @media(max-width: $from-tiny) {
        .b-fichiers_link {
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
            text-align: center;
        }

        .b-fichiers_wrap{
            width: 100%;
        }
    }
}
