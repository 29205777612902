.b-equipe {
    .b-equipe_slider_container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .b-equipe_img{
        position: relative;
    }

    .b-equipe_lkd{
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        background: linear-gradient(to right top, $primary, $second);
        position: absolute;
        left: 1rem;
        bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            width: 1rem;
        }
    }

    // .b-cards_slider_right{
    //     position: absolute;
    //     right: -5.5rem;
    //     bottom: 50%;
    //     top: 50%;

    //     &.-right{
    //         right: 0;
    //     }
    // }

    .b-equipe_slider {
        min-width: 100%;
    }
}
