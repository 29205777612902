.b-timeline{
    .b-timeline_container{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .b-timeline_title{
        display: flex;
        position: relative;

        span{
            position: absolute;
            font-size: $font-size-h2 * 2;
            left: 0;
            top: 0;
            color: #F0F0F0;
            transform: translateY(-25%);
            z-index: -1;
        }
    }

    .b-timeline_content{
        // max-width: rem(520px);

        figure{
            height: rem(190px);
            border-radius: 1rem;
            overflow: hidden;
        }
    }

    // @media (max-width: 850px){
    //     .b-timeline_container{
    //         flex-direction: column;
    //     }

    //     .b-timeline_title{
    //         margin-bottom: rem($unit);
    //     }
    // }
}
