.c-flap {
  position: fixed;
  z-index: 8;

  right: rem($unit-small);
  top: 50%;
  transform: translateY(-50%);

  .c-flap_wrap {
    display: flex;
    flex-direction: column;
    grid-gap: rem($unit-thin);
  }
  
  .c-flap_btn{
    .c-flap_label{
      display: none;
      padding: 0.5rem;
      background-color: $primary;
      border-radius: 5px;
    }

    &:hover{
      .c-flap_label{
        display: flex;
      }
    }

    @media (max-width: $from-small){
      flex-direction: column;

      .c-flap_label{
        position: absolute;
        top: -2.5rem;
        width: max-content;
      }
    }
  }

  @media(max-width: $from-small){
    bottom: 2rem;
    left: 50%;
    top: inherit;
    right: inherit;
    transform: translateX(-50%);

    .c-flap_wrap{
      flex-direction: row;
    }
  }
}

// .has-flapOpen {
//   .c-flap {
//     .c-flap_bg {
//       &::before {
//         visibility: visible;
//       }
//     }
//     .c-flap_button {
//       .c-flap_icon-open {
//         display: none;
//       }
//       .c-flap_icon-close {
//         display: block;
//       }
//       &.-hide {
//         transform: translateY(0);
//         opacity: 1;
//         visibility: visible;
//       }
//     }
//   }
// }
