.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;
  padding: rem($unit-tiny) 0;
  transition: opacity $speed $easing;
  background-color: inherit;

  .c-header_container{
    &.-page{
      padding-bottom: 2rem;
      border-bottom: 2px solid $grey;
      gap: 2rem;
      
      .c-header_height{
        display: flex;
        align-items: center;

        img{
          width: 7rem;
        }
      }
    }
  }

  .c-header_height{
    height: rem(80px);
  }

  .o-layout_item{
    vertical-align: middle;
  }

  .c-header_logo{
    img{
      transition: $speed $easing;
    }
  }

  .c-header_wrap {
    display: flex;
  }

  .c-header_nav {
    display: flex;
    margin-left: auto;

    .c-header_nav_burger{
        display: flex;
        align-items: center;
        gap: 0.5rem;

      .c-header_nav-icon{
        display: flex;
        flex-direction: column;

        span{
          width: rem(40px);
          height: 2px;
          background-color: $grey;
          transition: $speed $easing;

          &:nth-of-type(2) {
            margin: 0 auto;
          }
        }
      }
      &:hover{
        &:not(.is-active){
          .c-header_nav-icon{
            span{
              &:nth-of-type(2){
                width: 40%;
              }
            }
          }
        }
      }

      &.is-active{
        .c-header_nav-icon {
          span {
            &:nth-of-type(1) {
              transform: rotate(45deg);
              transform-origin: left;
              width: rem(28px);
            }
            &:nth-of-type(2) {
              opacity: 0;
            }
            &:nth-of-type(3) {
              transform: rotate(-45deg);
              transform-origin: left;
              width: rem(28px);
            }
          }
        }
      }
    }  
  }

  .c-header_tel {
    display: flex;
    align-items: center;
    grid-gap: rem($unit-tiny);
  }

  // @media (min-width: $from-small) {
  //   padding: 0 rem(40px);
  // }
  // @media (max-width: $to-small) {
  //   padding: 0 rem(20px);
  // }

  &.-scrolling{
    background-color: $header;
    height: 7rem;

    .c-header_container{
      &:not(.-page){
        .c-header_logo{
          img{
            transform: scale(0.65);
            position: relative;
            bottom: 2rem;
          }
        }
      }
      &.-page{
        padding-bottom: inherit;
        border-bottom: inherit;
      }
    }
  }

  @media (max-width: $from-medium) {
    .c-header_tel {
      display: none;
    }
  }

  @media (max-width: $from-small){
    .c-header_nav{
      button{
        &:nth-of-type(1) {
          margin-left: 0.5rem;
          margin-right: 1rem !important;
        }
        &:nth-of-type(3){
          display: none;
        }
        &:last-of-type{
          margin-right: 0.5rem !important;
          .c-button_label{
            display: none;
          }
        }
      }
    }
  }

  @media(max-width: $from-tiny){
    .logo{
      position: relative;
      top: 0.5rem;
    }

    .c-header_container {
      &.-page {
        gap: 0.5rem;

        .c-header_height {
          .c-header_logo {
            .logo {
              min-width: 6rem;
            }
          }
        }

        .c-header_nav{
          .c-header_nav_burger{
            .c-header_nav_burger_label{
              display: none;
            }
          }
        }
      }
    }

    &.-scrolling{
      .c-header_container{
        &:not(.-page){
          .c-header_logo img{
            transform: scale(1);
            position: relative;
            bottom: inherit;
          }
        }

        &.-page{
          gap: 0.5rem;

          .c-header_height{
            .c-header_logo{
              .logo{
                min-width: 6rem;
              }
            }
          }
        }
      }
    }
  }
}

.has-navOpen{
  .c-header{
    background-color: $header;
    height: 7rem;
    transition: 0s 0s;

    .c-header_container {
      &:not(.-page) {
        .c-header_logo {
          img {
            transform: scale(0.65);
            position: relative;
            bottom: 2rem;
          }
        }
      }
  
      &.-page {
        padding-bottom: inherit;
        border-bottom: inherit;
        transition: border-bottom 0s 0s;
      }
    }
  }
}
